import React from "react"
import TeamMembers from "./teamMembers"
import { document } from "browser-monads"

// Reset Scroll
document.body.scrollTo(0, 0)

const PostContent = props => {
  const { content, teamMembers, title, tags, iframe } = props
  const createMarkup = content => {
    return {
      __html: content,
    }
  }
  return (
    <div>
      {iframe && (
        <div id="iframe-wrap" dangerouslySetInnerHTML={createMarkup(iframe)} />
      )}
      <div>
        {title && <h1 style={{ marginTop: "100px" }}>{title}</h1>}
        {tags && tags.length > 0 && (
          <div className="tag-container-post">
            <ul>
              {tags.map((tag, index) => (
                <li key={index}>{tag.name}</li>
              ))}
            </ul>
          </div>
        )}
        <div dangerouslySetInnerHTML={createMarkup(content)} />
        {teamMembers && <TeamMembers teamArray={teamMembers} />}
      </div>
    </div>
  )
}

export default PostContent
