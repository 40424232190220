import React, { useState } from "react"
import { navigate } from "gatsby"
import { Card, Image } from "semantic-ui-react"
import emptyThumbnail from "../assets/img/empty-thumbnail.png"
import "semantic-ui-css/semantic.min.css"

const DisplayCard = props => {
  const post = props.props
  const [hover, setHover] = useState(false)
  const createMarkup = content => {
    return {
      __html: content,
    }
  }

  return (
    <Card
      onClick={() => {
        navigate(`/${post.slug}`)
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      className={hover ? "hover" : "no-hover"}
    >
      <Card.Content>
        <div className="display-card-image">
          {post.featuredImage ? (
            <div
              style={{
                backgroundImage: `url(${post.featuredImage.sourceUrl})`,
              }}
            />
          ) : (
            <div style={{ backgroundImage: `url(${emptyThumbnail})` }} />
          )}
        </div>
        <div className="display-card-text">
          <h1>{post.title}</h1>
          <p dangerouslySetInnerHTML={createMarkup(post.excerpt)} />
        </div>
      </Card.Content>
    </Card>
  )
}

export default DisplayCard
