import React from "react"
import { graphql } from "gatsby"
import { document } from "browser-monads"
import "semantic-ui-css/semantic.min.css"

import Layout from "../components/layout"
import Header from "../components/header"
import Contact from "../components/contact"
import VideoBanner from "../components/videoBanner"
import IndexSegment from "../components/indexSegment"
import DisplayCard from "../components/displayCard"

import meetingVid from "../assets/video/meeting.mp4"
import meetingImg from "../assets/img/projects.jpg"

import "../css/index.css"

export const pageQuery = graphql`
  query {
    allWpPost {
      nodes {
        title
        slug
        categories {
          nodes {
            name
          }
        }
        excerpt
        featuredImage {
          sourceUrl
        }
        gallery {
          gallery {
            sourceUrl
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`
// Reset Scroll
document.body.scrollTo(0, 0)

const ClientProjectsIndexPage = ({ data }) => {
  // console.log({ data })
  return (
    <div>
      <VideoBanner
        text="Client Projects"
        videoSrc={meetingVid}
        imageSrc={meetingImg}
      />
      <Header color="white" />
      <div className="index-bg-wrap">
        <div className="card-wrap index">
          {data.allWpPost.nodes
            .filter(post => {
              console.log({ post })
              if (post.categories.nodes[0].name === "Client Projects") {
                return post
              }
            })
            .map((post, index) => (
              <DisplayCard key={index} props={post} />
            ))}
        </div>
      </div>
      <Contact />
    </div>
  )
}

export default ClientProjectsIndexPage
